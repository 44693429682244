var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"data-app":""}},[_c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',{staticStyle:{"color":"#e33354"},attrs:{"cols":"12","sm":"6","md":"6"}},[_c('h3',[_vm._v(_vm._s(_vm.cardTitle))])]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('v-text-field',{attrs:{"append-icon":"search","label":_vm.$t('FORMS.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.userCollection,"search":_vm.search,"loading":_vm.loadingTable,"sort-by":['surname'],"footer-props":{
        'items-per-page-options': [100],
      }},on:{"click:row":_vm.handleClickItem},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('UserModalForm',{attrs:{"modalData":_vm.modalData,"userGroups":_vm.userGroupCollection,"roles":_vm.roleCollection,"sites":_vm.siteCollection,"statuses":_vm.statuses,"permissions":_vm.permissions},on:{"closeModalForm":_vm.handleCloseModalForm,"saveModalForm":_vm.handleSaveModalForm}})],1)]},proxy:true},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [(_vm.statuses.system)?_c('span',[_vm._v(_vm._s(_vm.statuses.system.user[item.status].value))]):_vm._e()]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return undefined}},{key:"header",fn:function(ref){
      var headers = ref.props.headers;
return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',[_c('th'),_c('th'),_c('th'),_c('th'),_c('th')])])]}}])}),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t("TABLE.are_you_sure_delete")))]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.closeDelete}},[_vm._v(_vm._s(_vm.$t("TABLE.cancel")))]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.deleteItemConfirm}},[_vm._v(_vm._s(_vm.$t("TABLE.ok")))]),_c('v-spacer')],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }